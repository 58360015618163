<template>
  <div class="w-full all p-2 pl-4 flex items-center" v-if="item !== null">
    <svg-icon
      name="jpg"
      class="h-6 cursor-pointer mr-4"
      original
      v-if="item.type === 'image/jpeg'"
    />

    <svg-icon
      name="png"
      class="h-6 cursor-pointer mr-4"
      original
      v-if="item.type === 'image/png'"
    />

    <div>
      <div class="titre">{{ item.name }}</div>
      <div class="sous-titre">{{ item.size }} ko</div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'index',
  components: {
  },

  props: {
    item: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
    }
  },

  methods: {
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/style/sass/variables";
.all{
  background: #FFFFFF;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  font-family: $font-default;
}

.titre{
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #191919;
}

.sous-titre{
  font-weight: normal;
  font-size: 10px;
  line-height: 13px;
  color: #979797;
}
</style>
