<template>
  <div class="tout w-full mr-auto ml-auto">
    <div class="flex justify-center">
      <svg-icon
        name="valid-green"
        class="h-20 cursor-pointer"
        original
      />
    </div>

    <div class="titre mt-6">
      Envoyé avec succès
    </div>
    <div class="sous-titre mt-5 md:w-2/3 mr-auto ml-auto">
      Votre déclaration de sinitre pour la prime
      <span v-if="item.product.categorie === 'AUTO'"> {{item.meta.carBrand}} - {{item.meta.carRegistration}}</span>
      <span v-if="item.product.categorie === 'MOTO'"> {{item.meta.motorBrand}} - {{item.meta.motoRegistration}}</span>
      <span v-if="item.product.categorie === 'MRH'"> {{item.meta.habitationType.toUpperCase()}}</span>
      <span v-if="item.product.categorie === 'VOYAGE'"> {{item.meta.ownerCountry.name}} - {{item.meta.country.name}}</span>
      a été bien reçu.
    </div>

    <div class="flex justify-center mt-6">
      <div class="button md:w-4/6" @click="openAttestation">
        Téléchargez votre attestation de declaration
      </div>
    </div>

    <div class="flex justify-center mt-6">
      <div class="button retour md:w-3/6" @click="goTo('home')">
        Retour à l’accueil
      </div>
    </div>

  </div>
</template>

<script>

export default {
  name: 'index',
  components: {
  },

  data () {
    return {
    }
  },
  props: {
    item: {
      type: Object,
      default: null
    },
    donnes: {
      type: Object,
      default: null
    }
  },

  mounted () {
  },

  methods: {
    goTo (index) {
      this.$router.push('/' + index)
    },

    openAttestation () {
      window.open(this.donnes.atestation, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/style/sass/variables";

.tout{
  width: 90%;
  font-family: $font-default;
  height: 100%;
  padding-bottom: 150px;
}

.titre{
  font-weight: bold;
  font-size: 28px;
  line-height: 160%;
  text-align: center;
  color: #000000;
}

.sous-titre{
  font-weight: normal;
  font-size: 20px;
  line-height: 160%;
  text-align: center;
  color: #000000;
}

.button{
  background: #0269AD;
  border: 1px solid #0269AD;
  box-sizing: border-box;
  border-radius: 10px;
  font-weight: normal;
  font-size: 20px;
  line-height: 153%;
  color: #FFFFFF;
  height: 60px;
}
.retour{
  background: #999999;
  border: 1px solid #999999;
}
</style>
