<template>
  <div class="aboutP w-full pt-4">
    <popup-charge :want-to-make-offer="activeCharge"/>
    <confirmSinistre :want-to-make-offer="activeConfirmation" v-if="activeConfirmation" @oga="retourConfirmation"/>
    <div class="fullContain mr-auto ml-auto">
        <div v-if="position === 1" class="h-full">
          <div class="titre mb-6">Prime concernée</div>

          <div class="md:flex justify-center items-center all p-5 pr-6 cursor-pointer mb-4" v-for="(item, index) in allPrime" :key="index" @click="openDetail(item)">
            <img src="../../../assets/icons/vehicule.svg" alt="" class="h-16 mr-5 mt-2 md:ml-4" v-if="item.product.categorie === 'AUTO' || item.product.categorie === 'Automobile'">
            <img src="../../../assets/icons/moto.svg" alt="" class="h-16 mr-5 mt-2 md:ml-4" v-if="item.product.categorie === 'MOTO' || item.product.categorie === 'Moto'">
            <img src="../../../assets/icons/voyage.svg" alt="" class="h-16 mr-5 mt-2 md:ml-4" v-if="item.product.categorie === 'VOYAGE' || item.product.categorie === 'Voyage'">
            <img src="../../../assets/icons/mrh.svg" alt="" class="h-16 mr-5 mt-2 md:ml-4" v-if="item.product.categorie === 'MRH' || item.product.categorie === 'Habitation'">

            <div class="md:w-3/5 md:ml-2">
              <div class="nom">
                <span v-if="item.product.categorie === 'AUTO' || item.product.categorie === 'Automobile'">{{item.meta.carBrand}} - {{item.meta.carRegistration}}</span>
                <span v-if="item.product.categorie === 'MOTO' || item.product.categorie === 'Moto'">{{item.meta.motorBrand}} - {{item.meta.motoRegistration}}</span>
                <span v-if="item.product.categorie === 'MRH' || item.product.categorie === 'Habitation'">{{item.meta.habitationType.toUpperCase()}}</span>
                <span v-if="item.product.categorie === 'VOYAGE' || item.product.categorie === 'Voyage'">{{item.meta.ownerCountry.name}} - {{item.meta.country.name}}</span>
              </div>
              <div class="expire md:mt-2">Expire dans {{expireDate(item.expireAt)}} jours</div>
            </div>

            <div class="md:w-2/5 md:flex justify-end items-center">
              <div class="md:flex md:w-1/3 justify-end">
                <div class="button pl-5 pr-5" v-if="expireDate(item.expireAt) > 0">
                    Active
                </div>
                <div class="button pl-5 pr-5 expir" v-if="expireDate(item.expireAt) <= 0">
                  Expirée
                </div>
              </div>
            </div>
          </div>

          <div class="nom text-center mt-6" v-if="activeCharge === false && allPrime.length === 0">Vous n'avez pas de souscriptions active</div>
        </div>

        <div v-if="position === 2" class="w-full">
          <detailsSinistre class="w-full" @info="retourSinDetail"/>
        </div>

      <div v-if="position === 3" class="w-full">
        <completeSinistre class="w-full" @info="retourComplete"/>
      </div>

      <div v-if="position === 4" class="w-full">
        <final-sinistre class="w-full" :item="seletedItem" :donnes="finalData"/>
      </div>

    </div>
    <footerSteep v-if="position > 1 && position < 4" @back="retourBack"  @next="retourNext"/>
  </div>
</template>

<script>
import auto from '@/assets/icons/vehicule.svg'
import moto from '@/assets/icons/moto.svg'
import mrh from '@/assets/icons/mrh.svg'
import voyage from '@/assets/icons/voyage.svg'
import detailsSinistre from '@/components/cedro/declaration/detailsSinistre'
import footerSteep from '@/components/cedro/declaration/footerSinistre'
import completeSinistre from '@/components/cedro/declaration/completeSinistre'
import confirmSinistre from '@/components/popup/confirmSinistre'
import finalSinistre from '@/components/cedro/declaration/finalSinistre'
import http from '@/plugins/http'
import apiroutes from '@/router/api-routes'
import popupCharge from '@/components/popup/popupCharge'

export default {
  name: 'index',
  components: {
    detailsSinistre,
    footerSteep,
    completeSinistre,
    confirmSinistre,
    finalSinistre,
    popupCharge
  },

  data () {
    return {
      auto,
      moto,
      mrh,
      voyage,
      allPrime: [],
      position: 1,
      seletedItem: null,
      activeConfirmation: false,
      activeCharge: true,
      reponses: {},
      firebaseId: '',
      degat: [],
      materiel: [],
      devis: [],
      proces: []
    }
  },

  created () {
    this.allSouscriptions()
    this.firebaseId = this.parseJwt(this.$store.getters.tokenState).firestoreId
  },

  methods: {
    retourSinDetail (answer) {
      this.reponses.details = answer
    },

    retourComplete (answer) {
      this.reponses.images = answer
    },

    openDetail (item) {
      if (this.expireDate(item.expireAt) > 0) {
        this.seletedItem = item
        this.position = this.position + 1
      }
    },

    expireDate (date) {
      const now = new Date().getTime()
      const temps = parseInt(new Date(date).getTime()) - now
      const jours = temps / (1000 * 3600 * 24)
      return jours.toFixed(0)
    },

    allSouscriptions () {
      http.get(apiroutes.baseURL + apiroutes.souscriptions)
        .then(response => {
          this.allPrime = response.data
          this.metaFactory()
        })
        .catch(error => {
          this.activeCharge = false
          console.log('erreur')
          console.log(error)
        })
    },

    metaFactory () {
      for (const item in this.allPrime) {
        this.allPrime[item].meta = JSON.parse(this.allPrime[item].meta)
      }
      this.activeCharge = false
    },

    retourBack (answer) {
      this.position = this.position - 1
    },

    retourNext (answer) {
      if (this.position < 3 && this.trackError() === 'ok') {
        this.position = this.position + 1
      } else {
        this.activeConfirmation = true
      }
    },

    trackError () {
      if (this.position === 2) {
        if (this.reponses.details !== undefined) {
          return 'ok'
        } else {
          return 'non'
        }
      } else if (this.position === 3) {
        if (this.reponses.images !== undefined) {
          return 'ok'
        } else {
          return 'non'
        }
      }
    },

    retourConfirmation (answer) {
      if (answer === true) {
        this.activeConfirmation = false
        this.createSinister()
      }
      this.activeConfirmation = false
    },

    createSinister () {
      this.activeCharge = true
      const prodId = this.seletedItem.product.id
      const categorie = this.seletedItem.product.categorie
      this.activeCharge = true
      http.post(apiroutes.baseURL + apiroutes.sinister, {
        body: {
          title: 'Sinistre',
          date: this.reponses.details.sinistreDate,
          place: this.reponses.details.lieu,
          circumstance: this.reponses.details.circonstance,
          name: this.reponses.images.nom,
          surname: this.reponses.images.prenom,
          profession: this.seletedItem.meta.profession,
          email: this.reponses.images.email,
          imagesOne: '',
          imagesTwo: '',
          imagesThree: '',
          imagesFour: '',
          police: this.seletedItem.policyNumber,
          phone: this.reponses.images.telephone,
          hospitalName: this.reponses.images.hopitalName,
          doctorPhone: this.reponses.images.docteurTel,
          doctorAddress: '',
          doctorName: this.reponses.images.docteur,
          hospitalPhone: '',
          adressHospital: this.reponses.images.hopitalAdresse,
          subscriptionData: {
            product: {
              categorie: categorie
            },
            meta: {
              subscriberName: this.seletedItem.meta.subscriberName
            }
          },
          autoDamage: this.reponses.details.degatPerso,
          autoOtherDamage: this.reponses.details.degatAutre,
          firebaseId: this.firebaseId,
          productId: prodId
        },
        subscription: {
          id: this.seletedItem.id
        },
        step: {
          id: 2
        },
        client: {
          id: this.parseJwt(this.$store.getters.tokenState).id
        },
        images: {}
      })
        .then(response => {
          this.activeCharge = false

          this.finalData = response.data
          this.position = this.position + 1

          console.log(response)
          this.sendImages()
        })
        .catch(error => {
          this.activeCharge = false
          console.log('erreur')
          console.log(error)
        })
    },

    sendImages () {
      this.finalData.body.imagesOne = this.reponses.images.degat
      this.finalData.body.imagesTwo = this.reponses.images.corporel
      this.finalData.body.imagesThree = this.reponses.images.proces
      this.finalData.body.imagesFour = this.reponses.images.devis
      this.finalData.images.imagesOne = this.reponses.images.degat
      this.finalData.images.imagesTwo = this.reponses.images.corporel
      this.finalData.images.imagesThree = this.reponses.images.proces
      this.finalData.images.imagesFour = this.reponses.images.devis
      http.patch(apiroutes.baseURL + apiroutes.sinister + '/' + this.finalData.id, {
        body: this.finalData.body,
        images: this.finalData.images
      })
        .then(response => {
          // console.log('success')
          // console.log(response)
        })
        .catch(error => {
          console.log('erreur images')
          console.log(error)
        })
    },

    parseJwt (token) {
      const base64Url = token.split('.')[1]
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
      const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      }).join(''))
      // console.log(JSON.parse(jsonPayload))
      return JSON.parse(jsonPayload)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/style/sass/variables";

.aboutP{
  width: 100%;
  font-family: 'Mulish';
  min-height: 100vh;
  padding-bottom: 50px;
}
.fullContain{
  width: 60%;
  height: 100%;
}
.titre{
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 64px;
  text-align: center;
  color: #000000;
}
.nom{
  font-weight: 600;
  font-size: 24px;
  line-height: 27px;
  color: #000000;
}
.expire{
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 27px;
  color: #909090;
}

.all{
  background: #FFFFFF;
  border-radius: 20px;
  height: 145px;
}
.button{
  background: #00BF53;
  border-radius: 18.3205px;
  height: 36.64px;
  font-weight: 500;
  font-size: 19.7298px;
  line-height: 26px;
  color: #FFFFFF;
  border: none;
}
.expir{
  background-color: #f6c6c6;
  color: red;
}
span{
  font-weight: 600;
  font-size: 24px;
  line-height: 27px;
  color: #606060;
}

@media screen and (max-width: 1024px) and (min-width: 770px){
  .fullContain{
    width: 70%;
  }
}

@media screen and (max-width: 769px) and (min-width: 701px){
  .fullContain{
    width: 90%;
  }
  .titre{
    font-size: 38px;
    margin-top: 0px !important;
    margin-bottom: 20px !important;
  }
}

@media screen and (max-width: 700px){
  .button{
    height: 20.64px;
    font-size: 10px;
    width: 20%;
  }
  .fullContain{
    width: 90%;
  }
  img{
    height: 2rem;
  }
  .titre{
    font-size: 28px;
    margin: 0px !important;
  }
  .aboutP{
    padding-top: 0px !important;
  }
  .nom{
    width: 100%;
    span{
      font-size: 18px;
    }
  }
  .expire{
    width: 100%;
    font-size: 10px;
  }
  .all{
    height: auto;
    padding: 15px 20px !important;
  }
}

</style>
