<template>
<div class="w-full h-full rounded-lg all flex justify-center items-center">
  <div class="text-center">
    <div class="flex justify-center">
      <img :src="icons" alt="" class="md:h-auto h-10">
    </div>

    <div class="nom mt-1" :class="{'ok': type !== null}">
      {{ nom }}
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: 'index',
  props: {
    nom: {
      type: String,
      default: ''
    },
    icons: {
      type: String,
      default: 'smilet'
    },
    type: {
      type: String,
      default: null
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/style/sass/variables";
  .nom{
    font-weight: 500;
    font-size: 18px;
    line-height: 34px;
    font-family: $font-default;
    color: #000000;
  }
.all{
  width: 210px;
  height: 186px;
  background: #FFFFFF;
  border-radius: 30px;
}
.ok{
  padding-top: 10px;
  padding-left: 30px;
  padding-right: 30px;
  text-align: center;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: #000000;
}
@media only screen and (max-width: 700px) {
  .all{
    width: 100%;
    height: 140.83px;
  }
  .nom{
    font-size: 13.6275px;
    line-height: 25px;
  }
}
@media screen and (max-width: 769px) and (min-width: 701px){
  .all{
    width: 100%;
    height: 140.83px;
  }
  .nom{
    font-size: 14.6275px;
    line-height: 25px;
  }
  img{
    height: 2rem;
  }
}
@media screen and (max-width: 1024px) and (min-width: 770px){
  .nom{
    font-size: 16.6275px;
    line-height: 25px;
    margin-top: 10px !important;
  }
  .all{
    width: 100%;
    height: 160.83px;
  }
  img{
    height: 3rem;
  }
}
</style>
