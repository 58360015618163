<template>
  <div class="tout w-full mr-auto ml-auto">
    <div class="titre mb-6">Veuillez fournir les détails sur le sinistre</div>

    <div class="sous-titre mb-2">Date du sinintre</div>
    <datePicker @info="retourDate" deseable="['afterToday']"/>

    <div class="sous-titre mt-5 mb-2">Lieu</div>
    <inputo @info="retourLieu"/>

    <div class="sous-titre mt-5 mb-2">Décrivez les circonstances de l’accident</div>
    <textareaa @info="retourCirconstance" placeholder="Comment l’accident est-il arrivé"/>

    <div class="sous-titre mt-5 mb-2">Description de vos dégâts</div>
    <textareaa @info="retourDegatPerso" placeholder="Avez-vous eu des dégâts ?"/>

    <div class="sous-titre mt-5 mb-2">Description des dégâts causés aux autres</div>
    <textareaa @info="retourDegat" placeholder="Quels dégâts avez-vous causés aux autres ?"/>
    {{ controleur }}
  </div>
</template>

<script>
import datePicker from '@/components/helper/form/datePicker'
import inputo from '@/components/helper/form/input'
import textareaa from '@/components/helper/form/textarea'

export default {
  name: 'index',
  components: {
    datePicker,
    inputo,
    textareaa
  },

  data () {
    return {
      sinistreDate: null,
      lieu: '',
      circonstances: '',
      degatPerso: '',
      degatAutre: ''
    }
  },
  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    controleur: function () {
      if (this.sinistreDate !== null && this.sinistreDate !== false && this.lieu !== '' && this.circonstances !== '' && this.degatPerso !== '' && this.degatAutre !== '') {
        const obj = {
          sinistreDate: new Date(this.sinistreDate).getTime(),
          lieu: this.lieu,
          circonstance: this.circonstances,
          degatPerso: this.degatPerso,
          degatAutre: this.degatAutre
        }
        this.$emit('info', obj)
      }
    }
  },

  methods: {
    retourDate (answer) {
      this.sinistreDate = answer
    },

    retourLieu (answer) {
      this.lieu = answer
    },

    retourCirconstance (answer) {
      this.circonstances = answer
    },

    retourDegat (answer) {
      this.degatAutre = answer
    },
    retourDegatPerso (answer) {
      this.degatPerso = answer
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/style/sass/variables";

.tout{
  width: 90%;
  font-family: $font-default;
  height: 100%;
  padding-bottom: 150px;
}
.titre{
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 64px;
  text-align: center;
  color: #000000;
}
.nom{
  font-weight: 600;
  font-size: 24px;
  line-height: 27px;
  color: #000000;
}
.sous-titre{
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  color: #191919;
}

.button{
  background: #00BF53;
  border-radius: 18.3205px;
  height: 36.64px;
  font-weight: 500;
  font-size: 19.7298px;
  line-height: 26px;
  color: #FFFFFF;
  border: none;
}
span{
  font-weight: 600;
  font-size: 24px;
  line-height: 27px;
  color: #606060;
}

@media screen and (max-width: 1024px) and (min-width: 770px){

}

@media screen and (max-width: 769px) and (min-width: 701px){

  .titre{
    font-size: 34px;
    margin-top: 0px !important;
    margin-bottom: 20px !important;
  }
  .tout{
    width: 90%;
  }
}

@media screen and (max-width: 700px){
  .button{
    height: 20.64px;
    font-size: 10px;
    width: 20%;
  }
  .titre{
    font-size: 24px;
    line-height: 40px;
  }
  .nom{
    width: 100%;
  }
}

@media screen and (max-width: 325px){
}
</style>
