<template>
  <div class="all">
    <textarea
      class="textarea p-3"
      :placeholder="placeholder"
      v-model="value"
    />
    {{ sendValue }}
  </div>
</template>

<script>
export default {
  name: 'Index',
  components: {
  },
  props: {
    placeholder: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      value: ''
    }
  },
  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    sendValue: function () {
      this.$emit('info', this.value)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/style/sass/variables";

.textarea {
  width: 100%;
  background: #FFFFFF;
  height: 96px;
  font-size: 18px;
  border: 1px solid #E8E8E8;
  box-sizing: border-box;
  border-radius: 15.2377px;
}
</style>
