<template>
  <div class="about-page w-full h-screen md:pt-4">
    <div class="w-full bloc1" v-if="!activPrime">
      <div class="titre">Quel type de sinitre voulez-vous déclarer ?</div>

      <div class="flex justify-center mt-24">
        <div class="1/4 mr-10 cursor-pointer">
          <steepBloc :icons="auto" nom="Assurance Automobile" type="1"/>
        </div>

        <div class="1/4 mr-10 cursor-pointer">
          <steepBloc :icons="moto" nom="Assurance Moto 2 roues" type="1"/>
        </div>

        <div class="1/4 mr-10 cursor-pointer">
          <steepBloc :icons="voyage" nom="Assurance Voyage" type="1"/>
        </div>

        <div class="1/4 cursor-pointer">
          <steepBloc :icons="mrh" nom="Assurance Habitation" type="1"/>
        </div>
      </div>
    </div>

    <div class="">
      <primeConcerne v-if="activPrime"/>
    </div>
  </div>
</template>

<script>
import steepBloc from '@/components/cedro/accueil/steepBloc'
import auto from '@/assets/icons/vehicule.svg'
import moto from '@/assets/icons/moto.svg'
import mrh from '@/assets/icons/mrh.svg'
import voyage from '@/assets/icons/voyage.svg'
import primeConcerne from '@/components/cedro/declaration/primeConcerne'

export default {
  name: 'index',
  components: {
    steepBloc,
    primeConcerne
  },
  data () {
    return {
      auto,
      moto,
      mrh,
      voyage,
      activPrime: true
    }
  },
  created () {
    this.$store.dispatch('position', 1)
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/style/sass/variables";

.about-page{
  width: 100%;
  font-family: $font-default;
  height: 100%;
}

.bloc1{
  width: 60%;
  margin-right: auto;
  margin-left: auto;
}

.titre{
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 64px;
  text-align: center;
  color: #000000;
}

.num{
  font-weight: bold;
  font-size: 64px;
  line-height: 34px;
  color: rgba(2, 105, 173, 0.1);
}
.sous{
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  color: #49BF7C;
}

@media screen and (max-width: 1024px) and (min-width: 770px){
}

@media screen and (max-width: 769px) and (min-width: 701px){
}

@media screen and (max-width: 700px){
  .about-page{
    padding-top: 0px;
  }
}

@media screen and (max-width: 325px){
}
</style>
