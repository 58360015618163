<template>
  <div class="the_headert w-full h-full">

    <div class="w-full h-full flex items-center justify-center">
      <div class="mr-8 cursor-pointer" @click="goBack()" >Annuler</div>

      <div class="button" @click="goNext()">
        <span>Suivant</span>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Index',
  components: {
  },
  data () {
    return {
    }
  },
  props: {
    steep: {
      type: String,
      default: ''
    }
  },
  methods: {
    retourSign (answer) {
      this.activeSign = answer
    },

    goNext () {
      this.$emit('next', true)
    },

    goBack () {
      this.$emit('back', true)
    }
  }
}
</script>

<style lang="scss" scoped>
    @import "../../../assets/style/sass/variables";

    .the_headert {
        padding: 10px;
        width: 100%;
        height: auto;
        background-color: #fff;
        z-index: 19;
        position: fixed;
        bottom: 0;
      box-shadow: -1px -1px 20px 0px #0000001a;
    }
    div{
      font-size: 18px;
      font-weight: 500;
    }
.button{
  background-color: $base-color;
  border-radius: 10px;
  height: 53px;
  width: 15%;
  color: $white;
  border: none;
}
    @media screen and (max-width: 1024px) and (min-width: 701px){
        .the_headert {
            padding: 30px 0px 30px 0px;
        }
      .button{
        width: 20%;
      }
    }
    @media screen and (max-width: 700px){
        .the_headert {
            padding: 10px 0px 10px 0px;
        }
      .button{
        height: 40px;
        width: 40%;
      }
div{
  font-size: 15px;
}
    }
</style>
