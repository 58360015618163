<template>
  <div class="tout w-full mr-auto ml-auto">
    <div class="titre mb-6">Complètez vos informations</div>
    <div class="compressor">
      <image-compressor
        :done="getFiles"
        :scale="scale"
        :quality="quality"
        ref="compressor"
      >
      </image-compressor>
    </div>

    <div class="sous-titre mb-2">Prénoms*</div>
    <inputo @info="retourPrenom"/>

    <div class="sous-titre mt-5 mb-2">Nom*</div>
    <inputo @info="retourNom"/>

    <div class="sous-titre mt-5 mb-2">Téléphone*</div>
    <inputo type="number" @info="retourTelephone"/>

    <div class="sous-titre mt-5 mb-2">Email*</div>
    <inputo @info="retourEmail"/>

    <div class="sous-titre mt-5 mb-2">Nom de l'hôpital*</div>
    <inputo @info="retourHopital"/>

    <div class="sous-titre mt-5 mb-2">Adresse de l'hôpital*</div>
    <inputo @info="retourHopitalAdresse"/>

    <div class="sous-titre mt-5 mb-2">Nom du docteur</div>
    <inputo @info="retourDocteur"/>

    <div class="sous-titre mt-5 mb-2">Contact du docteur</div>
    <inputo @info="retourDocteurTel"/>

    <div class="mt-6">
      <div class="sous-titre mb-4">Images des dégats corporels</div>

      <div class="flex items-center mb-2" v-for="(item, index) in corporel" :key="index">
        <div class="mr-2 w-full">
          <image-apercu :item="item" v-if="item"/>
        </div>
        <svg-icon
          @click="deleteImage(index, 'corporel')"
          name="delete"
          class="h-5 cursor-pointer"
          original
        />
      </div>

      <div class="mt-5 button addImage" @click="selectedImage('corporel')">
        <div class="mr-4">Ajouter les images</div>
        <svg-icon
          name="download"
          class="h-4 cursor-pointer ico"
          original
          color="white"
        />
      </div>
    </div>

    <div class="mt-6">
      <div class="sous-titre mb-4">Images des dégats matériels</div>

      <div class="flex items-center mb-2" v-for="(item, index) in degat" :key="index">
        <div class="mr-2 w-full">
          <image-apercu :item="item" v-if="item"/>
        </div>
        <svg-icon
          @click="deleteImage(index, 'degat')"
          name="delete"
          class="h-5 cursor-pointer"
          original
        />
      </div>

      <div class="mt-5 button addImage" @click="selectedImage('degat')">
        <div class="mr-4">Ajouter les images</div>
        <svg-icon
          name="download"
          class="h-4 cursor-pointer ico"
          original
          color="white"
        />
      </div>
    </div>

    <div class="mt-6">
      <div class="sous-titre mb-4">Copie du Procès verbal délivré par la police ou la gendarmerie</div>

      <div class="flex items-center mb-2" v-for="(item, index) in proces" :key="index">
        <div class="mr-2 w-full">
          <image-apercu :item="item" v-if="item"/>
        </div>
        <svg-icon
          @click="deleteImage(index, 'proces')"
          name="delete"
          class="h-5 cursor-pointer"
          original
        />
      </div>

      <div class="mt-5 button addImage" @click="selectedImage('proces')">
        <div class="mr-4">Ajouter les images</div>
        <svg-icon
          name="download"
          class="h-4 cursor-pointer ico"
          original
          color="white"
        />
      </div>
    </div>

    <div class="mt-6">
      <div class="sous-titre mb-4">Devis ou factures des réparations</div>

      <div class="flex items-center mb-2" v-for="(item, index) in devis" :key="index">
        <div class="mr-2 w-full">
          <image-apercu :item="item" v-if="item"/>
        </div>
        <svg-icon
          @click="deleteImage(index, 'devis')"
          name="delete"
          class="h-5 cursor-pointer"
          original
        />
      </div>

      <div class="mt-5 button addImage" @click="selectedImage('devis')">
        <div class="mr-4">Ajouter les images</div>
        <svg-icon
          name="download"
          class="h-4 cursor-pointer ico"
          original
          color="white"
        />
      </div>
    </div>
    {{ controleur }}
  </div>
</template>

<script>
import inputo from '@/components/helper/form/input'
import imageApercu from '@/components/helper/add/imageApercu'
import imageCompressor from 'vue-image-compressor'

export default {
  name: 'index',
  components: {
    inputo,
    imageApercu,
    imageCompressor
  },

  data () {
    return {
      hopitalAdresse: '',
      prenom: '',
      nom: '',
      telephone: '',
      email: '',
      hopitalName: '',
      degat: [],
      proces: [],
      devis: [],
      corporel: [],
      degatC: [],
      procesC: [],
      devisC: [],
      corporelC: [],
      docteurTel: '',
      docteur: '',

      img: '',
      scale: 100,
      quality: 30,
      originalSize: true,
      original: {},
      indexImage: null
    }
  },
  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    controleur: function () {
      if (this.nom !== '' && this.prenom !== '' && this.email !== '' && this.telephone !== '' && this.hopitalName !== '' && this.hopitalAdresse !== '') {
        const obj = {
          nom: this.nom,
          prenom: this.prenom,
          email: this.email,
          telephone: this.telephone,
          degat: this.degatC,
          proces: this.procesC,
          devis: this.devisC,
          corporel: this.corporelC,
          hopitalName: this.hopitalName,
          hopitalAdresse: this.hopitalAdresse,
          docteur: this.docteur,
          docteurTel: this.docteurTel
        }
        this.$emit('info', obj)
      }
    }
  },

  created () {
    this.$store.dispatch('position', 1)
  },

  methods: {

    getFiles (obj) {
      this.img = obj.compressed.blob
      this.original = obj.original
      this.compressed = obj.compressed

      if (obj.compressed.size !== '0 kB') {
        if (this.indexImage === 'degat') {
          this.degat.push(obj.compressed.file)
          this.degatC.push(obj.compressed.base64)
        }
        if (this.indexImage === 'proces') {
          this.proces.push(obj.compressed.file)
          this.procesC.push(obj.compressed.base64)
        }
        if (this.indexImage === 'devis') {
          this.devis.push(obj.compressed.file)
          this.devisC.push(obj.compressed.base64)
        }
        if (this.indexImage === 'corporel') {
          this.corporel.push(obj.compressed.file)
          this.corporelC.push(obj.compressed.base64)
        }
        // console.log(obj)
      } else {
        this.quality = Math.floor(Math.random() * 40)
        // console.log(this.quality)
      }
    },

    retourNom (answer) {
      this.nom = answer
    },

    retourDocteur (answer) {
      this.docteur = answer
    },

    retourDocteurTel (answer) {
      this.docteurTel = answer
    },

    retourPrenom (answer) {
      this.prenom = answer
    },

    retourEmail (answer) {
      this.email = answer
    },

    retourTelephone (answer) {
      this.telephone = answer
    },

    retourHopital (answer) {
      this.hopitalName = answer
    },

    retourHopitalAdresse (answer) {
      this.hopitalAdresse = answer
    },

    deleteImage (index, type) {
      if (type === 'degat') {
        this.degat.splice(index, 1)
        this.degatC.splice(index, 1)
      }
      if (type === 'corporel') {
        this.corporel.splice(index, 1)
        this.corporelC.splice(index, 1)
      }
      if (type === 'proces') {
        this.proces.splice(index, 1)
        this.procesC.splice(index, 1)
      }
      if (type === 'devis') {
        this.devis.splice(index, 1)
        this.devisC.splice(index, 1)
      }
    },

    selectedImage (index) {
      this.indexImage = index
      const compressor = this.$refs.compressor.$el
      compressor.click()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/style/sass/variables";

.tout{
  width: 90%;
  font-family: $font-default;
  height: 100%;
  padding-bottom: 150px;
}
.compressor{
  display: none;
}
.titre{
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 64px;
  text-align: center;
  color: #000000;
}
.nom{
  font-weight: 600;
  font-size: 24px;
  line-height: 27px;
  color: #000000;
}
.sous-titre{
  font-family: 'Mulish';
  font-weight: normal;
  font-size: 17.7655px;
  line-height: 27px;
  color: #000000;
}

.ico{
  transform: rotate(-180deg);
}

.addImage{
  height: 35.9px;
  background: #0269AD;
  border-radius: 7.18049px;
  font-weight: normal;
  font-size: 12.5659px;
  line-height: 160%;
  color: #FFFFFF;
  border: none;
}
span{
  font-weight: 600;
  font-size: 24px;
  line-height: 27px;
  color: #606060;
}

@media screen and (max-width: 1024px) and (min-width: 770px){

}

@media screen and (max-width: 769px) and (min-width: 701px){

  .titre{
    font-size: 34px;
    margin-top: 0px !important;
    margin-bottom: 20px !important;
  }
  .tout{
    width: 90%;
  }
}

@media screen and (max-width: 700px){
  .button{
    height: 20.64px;
    font-size: 10px;
    width: 20%;
  }
  .titre{
    font-size: 24px;
    line-height: 40px;
  }
  .nom{
    width: 100%;
  }
  .sous-titre{
    font-size: 16px;
  }
}

@media screen and (max-width: 325px){
}
</style>
